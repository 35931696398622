export const gridApis = {
  authentication: "/v1/grid/0/token/authentication",
  getAllAccounts: "/v1/grid/0/account/getAllAccounts",
  getByID: "/v1/grid/0/bank/account/getByID",
  masterAccountTransactions:
    "/v1/grid/0/bank/account-transactions/masterAccountTransactions",
  initialisePayment: "/v1/grid/0/bank/payment/initialisePayment",
  confirmPayment: "/v1/grid/0/bank/payment/confirmPayment",
  cancelPayment: "/v1/grid/0/bank/payment/cancelPayment",
  getPaymentPurposes: "/v1/grid/0/bank/payment/getPaymentPurposes",
  getAllPayments: "/v1/grid/0/bank/payment/getAllPayments",
  createAccount: "/v1/grid/0/bank/account/create",
};
