import React, { useEffect } from "react";
import close from "../../assets/icons/close.png";
import usePayAxios from "../../config/useAxios";
import { gridApis } from "../../config/GridApis";
import { useForm } from "react-hook-form";
import { FormInput, FormSelect } from "../inputs";
import { useDispatch, useSelector } from "react-redux";
import { get_currencies } from "../../actions/masterdata/masterdata";

const AddAccount = ({ setOpenAddNew, cb }) => {
  const dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
  } = useForm({
    person_name: "",
    account_name: "",
  });

  const { onCall: addAcc } = usePayAxios({
    api: gridApis.createAccount,
    method: "post",
    type: "grid",
  });

  const onSubmit = (data) => {
    addAcc({ data: { ...data, person_id: "ID-1146" } }).then((res) => {
      setOpenAddNew(false);
      cb();
    });
  };

  useEffect(() => {
    // onCall({});
    dispatch(get_currencies());
  }, []);
  return (
    <div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="pp-form-item">
            <FormInput
              label={"Person Name"}
              control={control}
              placeholder={"Enter Person Name"}
              required={true}
              {...register("person_name", {
                required: "Person name is required.",
              })}
              errors={errors}
            />
          </div>
          <div className="pp-form-item">
            <FormInput
              label={"Account Name"}
              control={control}
              placeholder={"Enter Account Name"}
              required={true}
              {...register("account_name", {
                required: "Account name is required.",
              })}
              errors={errors}
            />
          </div>
          <div>
            <button type="button" className="back-btn mr2">
              Cancel
            </button>
            <button type="submit" className="send-btn mt3">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddAccount;
