import { Button, Collapse, message, Modal } from "antd";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  create_customers,
  CreateCustomer,
  customers,
} from "../../actions/customers";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import "../../components/Transfers/transfer.css";
import { apis } from "../../config/APIs";
import usePayAxios from "../../config/useAxios";
import { FormInput, FormSelect } from "../inputs";
import { useTranslation } from "react-i18next";
import { get_currencies } from "../../actions/masterdata/masterdata";
import AddVendorAddress from "../Sales/AddCustomerAddress";
import { p_types } from "../Payments/SendMoney/Recipients";

const CreateManageBeneficiary = ({ isVisible, onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const create_manage_bene = useSelector((state) => state.customers?.create);
  const currenciesRes = useSelector((state) =>
    state?.currencies?.data?.data?.length
      ? state?.currencies?.data?.data?.map((ele) => ({
          label: (
            <span
              style={{
                color: "#212121",
                fontSize: 15,
              }}
            >
              <img
                src={`https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${ele?.currency?.country_code?.toLowerCase()}.svg`}
                className="mr1"
                width={25}
                style={{
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                }}
              />
              {`${ele?.currency?.country_name} (${ele?.iso_code})`}
            </span>
          ),
          value: ele?.id,
          country_name: ele?.currency?.country_name,
          iso_code: ele?.iso_code,
        }))
      : []
  );
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    defaultValues: {
      company_id: "",
      method: "",
      company_name: "",
      beneficiary_name: "",
      beneficiary_type: "",
      primary_contact: "",
      email: "",
      account_number: "",
      ifsc_code: "",
      swift_code: "",
      branch: "",
      company_currency_id: "",
      routing_code: "",
      shippingAddressSame: true,
      billing_address: [
        {
          country: "",
          address: "",
          city: "",
          state: "",
          zipcode: "",
          phone: "",
          address_type: "billing",
        },
      ],
      shipping_address: [
        {
          country: "",
          address: "",
          city: "",
          state: "",
          zipcode: "",
          phone: "",
          address_type: "shipping",
        },
      ],
    },
    shouldUseNativeValidation: false,
  });

  const onSubmit = (data) => {
    let payload = {
      company_id: localStorage.getItem("company_id"),
      company_name: "-",
      beneficiary_name: data.beneficiary_name,
      beneficiary_type: "individual",
      company_currency_id: data.company_currency_id,
      primary_contact: data.primary_contact,
      email: data.email,
      billing_address: data.billing_address,
      shipping_address: data.billing_address,
      same_as_billing_address: true,
      bank_account_details: [
        {
          account_number: data.account_number,
          ifsc_code: data.ifsc_code,
          branch: data.branch,
          routing_code: data?.routing_code,
          swift_code: data?.swift_code,
        },
      ],
    };
    dispatch(create_customers(payload));
  };

  useEffect(() => {
    dispatch(get_currencies());
  }, []);

  useEffect(() => {
    if (create_manage_bene?.data?.error === false) {
      message.success(
        <span className="messageText">{create_manage_bene?.data?.message}</span>
      );
      reset();
      dispatch(
        customers({
          type: "individual",
          page_number: 1,
        })
      );
      onClose();
      dispatch({
        type: CreateCustomer.RESET,
      });
    }
    if (create_manage_bene?.data?.error) {
      message.error(
        <span className="messageText">{create_manage_bene?.data?.message}</span>
      );
    }
  }, [create_manage_bene]);

  const method = watch("method");
  return (
    <>
      <Modal
        onCancel={onClose}
        className="right-alinged-modal add-modal"
        width={720}
        title={
          <div className="d-flex">
            <div className="flex-grow">
              <span className="title">{t("create_manage_benificiary")}</span>
              <span
                className="fs16 lh20 color-818181 fw-medium"
                style={{
                  marginLeft: "18px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#818181",
                  fontWeight: "medium",
                }}
              >
                {t("enter_details")}
              </span>
            </div>
          </div>
        }
        visible={isVisible}
      >
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="pp-form-item">
                <FormSelect
                  inline
                  label={"Method"}
                  control={control}
                  required={true}
                  options={p_types}
                  {...register("method", {
                    required: "Please select the method.",
                    onChange: (e) => {
                      console.log(currenciesRes);
                      
                      if (e.target.value?.includes("LOCAL_US_ACH")) {
                        setValue(
                          "company_currency_id",
                          currenciesRes?.find((ele) => ele.iso_code === "USD")
                            ?.id
                        );
                      }
                    },
                  })}
                  errors={errors}
                  hideCreateOption={true}
                />
              </div>
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("beneficiary_name", {
                    required: t("beneficiary_name_required"),
                  })}
                  required
                  errors={errors}
                  label={t("beneficiary_name")}
                  placeholder={t("enter_benificiary")}
                />
              </div>
              <div className="pp-form-item">
                <FormSelect
                  control={control}
                  {...register("company_currency_id", {
                    required: "Currency is required",
                  })}
                  placeholder="Select Currency"
                  errors={errors}
                  filterKey={`country_name`}
                  label={t("Currency")}
                  required={true}
                  hideCreateOption
                  options={currenciesRes}
                />
              </div>
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("account_number", {
                    required: t("account_number_required"),
                  })}
                  required
                  errors={errors}
                  label={t("number_account")}
                  placeholder={t("enter_number")}
                />
              </div>
              {method?.includes("SWIFT") ? (
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("swift_code", {
                      required: t("SWIFT Code is required"),
                    })}
                    required
                    errors={errors}
                    label={t("SWIFT Code")}
                    placeholder={t("Enter SWIFT Code")}
                  />
                </div>
              ) : (
                <div className="pp-form-item">
                  <FormInput
                    control={control}
                    {...register("routing_code", {
                      required: t("Routing Number is required"),
                    })}
                    required
                    errors={errors}
                    label={t("Routing Number")}
                    placeholder={t("Enter Routing Number")}
                  />
                </div>
              )}
              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("branch", { required: t("bank_name_required") })}
                  required
                  errors={errors}
                  label={t("bank_name")}
                  placeholder={t("enter_bank_name")}
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("email", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: t("enter_valid_Email"),
                    },
                    required: t("email_required"),
                  })}
                  errors={errors}
                  required={true}
                  label={t("Email")}
                  placeholder={t("email_add")}
                />
              </div>

              <div className="pp-form-item">
                <FormInput
                  control={control}
                  {...register("primary_contact", {
                    required: t("phone_Required"),
                    pattern: {
                      value: /^[0-9]{10}$/i,
                      message: t("enter_valid_number"),
                    },
                  })}
                  errors={errors}
                  number={true}
                  required={true}
                  label={t("phone")}
                  placeholder={t("enter_phone")}
                />
              </div>
              <div className="additional-div">
                <div className="bg">
                  <span>{t("additional_info")} (Optional)</span>
                </div>

                <div className="collapse-div">
                  <Collapse
                    defaultActiveKey={["otherInformation"]}
                    onChange={() => {}}
                    className="pp-collapse"
                    expandIconPosition="end"
                    expandIcon={() => <CaretDownOutlined />}
                  >
                    <Collapse.Panel
                      header={
                        <>
                          <div className="pp-collapse-header">
                            {t("address")}
                          </div>
                        </>
                      }
                      key="address"
                    >
                      <AddVendorAddress
                        control={control}
                        register={register}
                        watch={watch}
                        setValue={setValue}
                        errors={errors}
                        billing_address={watch("billing_address")}
                        shipping_address={[]}
                        noShowSame={true}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "2rem" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="pp-main-button"
                id="save"
              >
                <span>{t("create")}</span>
              </Button>

              <Button
                className="pp-secondary-button"
                style={{ marginLeft: "2rem" }}
                onClick={onClose}
              >
                <span>{t("cancel")}</span>
              </Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default CreateManageBeneficiary;
