import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Col, Menu, Row } from "antd";
import { saveAs } from "file-saver";
import  json2csv  from "json2csv";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { customers } from "../../actions/customers";
import { headerTitles } from "../../actions/masterdata/masterdata";
import CreateManageBeneficiary from "../Common/CreateManageBeneficiary";
import TableComponent from "../Common/TableComp/TableComp";
import TableHeader from "../Sales/TableHeader";

const ManageBeneficiary = ({ hasWriteAccess }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [filters, setFilters] = useState({});

  const columns = [
    {
      title: t("beneficiary_name"),
      dataIndex: "beneficiary_name",
    },
    {
      title: t("account_num"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return text?.[0]?.account_number;
      },
    },
    {
      title: t("ifsc_code"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return text?.[0]?.ifsc_code;
      },
    },
    {
      title: t("bank_name"),
      dataIndex: "bank_accounts",
      render: (text) => {
        return text?.[0]?.branch;
      },
    },
    {
      title: t("Currency"),
      dataIndex: "currency_info",
      render: (text) => <div>{text?.iso_code}</div>,
    },
    {
      title: t("email"),
      dataIndex: "email",
    },
    {
      title: t("phone_no"),
      dataIndex: "primary_contact",
    },
  ];

  useEffect(() => {
    dispatch(
      customers({
        type: "individual",
      })
    );
    dispatch(
      headerTitles({
        title: "Recipients",
        description: "Add Recipients to transfer and receive funds.",
      })
    );
    // eslint-disable-next-line
  }, []);

  const handleAdd = () => {
    setOpenAddNew(!openAddNew);
  };

  const handleSearch = (value) => {
    dispatch(
      customers({
        type: "individual",
        search_key: value,
      })
    );
    setFilters({
      ...filters,
      search_key: value,
    });
    setPage(1);
  };

  function handleMenuClick(e) {
    if (e?.key === "1") handleAdd();
    else history.push("/upload-bulk-beneficiaries");
  }

  const menu = (
    <Menu
      onClick={(e) => {
        hasWriteAccess && handleMenuClick(e);
      }}
    >
      <Menu.Item key="1" icon={<PlusCircleOutlined />}>
        {t("add_new_user")}
      </Menu.Item>
      <Menu.Item key="2" icon={<UploadOutlined />}>
        {t("Upload beneficiaries")}
      </Menu.Item>
    </Menu>
  );

  const manageResponse = useSelector(
    (state) => state.customers?.all?.data?.data || 0
  );
  const totalRecords = useSelector(
    (state) => state.customers?.all?.data?.total_count || 0
  );

  return (
    <>
      <CreateManageBeneficiary
        isVisible={openAddNew && hasWriteAccess}
        onClose={() => {
          setOpenAddNew(false);
        }}
      />
      <Row wrap={false} className="table-wrapper">
        <Col flex="auto" className="table-wrapper-col">
          <div className="flex-grow">
            <TableHeader
              //   filterMenu={filterOptions}
              // handleFilter={handleFilter}
              noFilter={true}
              handleAddButton={handleAdd}
              menu={menu}
              noAddNewBtn={false}
              hasExport={selectedRows?.length > 0}
              hasWriteAccess={hasWriteAccess}
              handleExport={() => {
                const prods1 = selectedRows?.map((ele) =>
                  location?.state?.fromPayLinks
                    ? {
                        Email: ele.email,
                        "Phone No": ele.primary_contact,
                        "Payment Amount": "",
                      }
                    : {
                        "Beneficiary Name": ele.beneficiary_name,
                        "Beneficiary Email": ele.email,
                        "Bank Name": ele.bank_accounts?.[0]?.branch,
                        "Bank account No":
                          ele.bank_accounts?.[0]?.account_number,
                        "IFSC Code": ele.bank_accounts?.[0]?.ifsc_code,
                        Amount: "",
                        "Phone No": ele.primary_contact,
                      }
                );
                const filename = "Beneficiaries";
                let csv = json2csv.parse(prods1);

                var blob = new Blob([csv], {
                  type: "text/csv;charset=utf-8",
                });
                saveAs(blob, `${filename}.csv`);
                setSelectedRowKeys([]);
                setSelectedRows([]);
              }}
              //   sortOptions={sortOptions}
              //   handleSort={handleSort}
              onSearch={handleSearch}
              //   menu={options}
            />
            <div className="" style={{ paddingTop: "22px" }}>
              <TableComponent
                columns={columns}
                loading=""
                data={manageResponse}
                pagination={{
                  total: totalRecords || 0,
                  current: page,
                  onChange: (pagee) => {
                    dispatch(
                      customers({
                        page_number: pagee,
                        type: "individual",
                      })
                    );
                    setPage(pagee);
                  },
                }}
                rowSelection={{
                  type: "checkbox",
                  preserveSelectedRowKeys: true,
                  // ...rowSelection,
                  // onChange: onChangeCheckBox,
                  getCheckboxProps: (record) => ({
                    checked: selectedRows?.find((ele) => ele.id === record.id)
                      ? true
                      : false,
                    // disabled: loading,
                  }),
                  selectedRowKeys: selectedRowKeys,
                  onSelect: (record, selected) => {
                    if (selected) {
                      setSelectedRows((rows) => [...rows, record]);
                      setSelectedRowKeys((rows) => [...rows, record.id]);
                    } else {
                      let rows = selectedRows;
                      let idx = rows.findIndex((ele) => ele.id === record.id);
                      if (idx > -1) {
                        rows.splice(idx, 1);
                      }
                      setSelectedRows(rows);
                      setSelectedRowKeys(rows?.map((ele) => ele.id));
                    }
                  },
                  onSelectAll: (checked, records) => {
                    if (checked) {
                      setSelectedRows((rows) => [...rows, ...records]);
                      setSelectedRowKeys((rows) => [
                        ...rows,
                        ...records?.map((ele) => ele?.id),
                      ]);
                    } else {
                      let rows = selectedRows;
                      manageResponse.forEach((itm) => {
                        let idx = rows.findIndex((ele) => ele.id === itm.id);
                        if (idx > -1) {
                          rows.splice(idx, 1);
                        }
                      });
                      setSelectedRows(rows);
                      setSelectedRowKeys(rows?.map((ele) => ele.id));
                    }
                  },
                }}
                // handleRowClick={handleSelectRow}
                rowKey="id"
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ManageBeneficiary;
