import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { headerTitles } from "../../actions/masterdata/masterdata";
import { get_all_payments } from "../../actions/z_accounts";
import { payment_status } from "../../config/helper";
import BankShow from "../Common/BankShow";
import BaseCurrency from "../Common/BaseCurrency";
import Table from "../Common/Table/Table";
import { p_types } from "../Payments/SendMoney/Recipients";
import SendMoney from "../Payments/SendMoney/SendMoney";
// import Base from "antd/lib/typography/Base";

const AccountTransaction = ({ sendMoney }) => {
  const dispatch = useDispatch();
  const [openAddNew, setOpenAddNew] = useState(false);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});
  const masterHistoryResponse = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );
  const totalCount = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.total_count ?? []
  );

  React.useEffect(() => {
    // dispatch(accountTxns(({ page_number: 1 })));
    dispatch(
      headerTitles(
        sendMoney
          ? {
              title: "Send Money",
              description: "Payments Transfer to Recipients Accounts",
            }
          : {
              title: "account_transactions",
              description: "View all your global account transactions",
            }
      )
    );

    dispatch(get_all_payments({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendMoney]);
  const { t } = useTranslation();

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "preferred_payment_scheme",
      key: "preferred_payment_scheme",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <div>
          <BaseCurrency />
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status_code",
      key: "status_code",
      render: (text) => (
        <span
          className={`status status_${payment_status[text]?.toLowerCase()}`}
        >
          {payment_status[text]}
        </span>
      ),
    },
  ];

  return openAddNew ? (
    <>
      <SendMoney setOpenAddNew={setOpenAddNew} />
    </>
  ) : (
    <>
      <BankShow
        noBack={true}
        noDownload={true}
        addBtn={sendMoney}
        setOpenAddNew={setOpenAddNew}
        text={"Send Money"}
        callAction={(acc, s_date, e_date) => {
          const obj =
            s_date && e_date
              ? {
                  created_at: {
                    start_date: s_date,
                    end_date: e_date,
                  },
                }
              : {};
          setFilters({ ...obj, account_numnber: acc });
          dispatch(
            get_all_payments({
              params: { account_numnber: acc },
              filters: obj,
            })
          );
        }}
      />
      <br />
      <Table
        rowKey="id"
        columns={columns}
        data={masterHistoryResponse}
        isTopFilter={false}
        className="tableStyled"
        topFilters={[
          { label: t("all_trans"), action: () => {} },
          { label: t("advance_search"), action: () => {} },
        ]}
        scroll={{
          x: 1250,
        }}
        pagination={{
          total: totalCount,
          current: page,
          onChange: (pagee) => {
            dispatch(
              get_all_payments({
                params: {
                  page_number: pagee,
                  account_numnber: filters?.account_numnber,
                },
                filters: filters?.created_at,
              })
            );
            setPage(pagee);
          },
        }}
      />
    </>
  );
};

export default AccountTransaction;
