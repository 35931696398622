import {
  EyeInvisibleOutlined,
  EyeOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { SideMenu, headerTitles } from "../../actions/masterdata/masterdata";
import { get_person_accounts } from "../../actions/z_accounts";
import { currencyMapper, currencyNames } from "../../config/helper";
import { amountFormat } from "../../utils";
import TableComponent from "../Common/TableComp/TableComp";
import AddNewPrimaryAcc from "./AddNewPrimaryAcc";
import AddAccount from "./AddAccount";

const PrimaryAcc = ({ hasWriteAccess }) => {
  const [openNewAcc, setOpenNewAcc] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const location = useLocation();
  const { t } = useTranslation();
  const bankAcc = useSelector(
    (state) =>
      state?.z_accs?.accounts?.data?.data?.responseData?.data?.values || []
  );

  console.log({
    bankAcc,
  });

  const bankAccLoading = useSelector(
    (state) => state?.z_accs?.accounts?.loading
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [showBalance, setShowBalance] = useState({});

  const columns = [
    {
      title: t("Currency"),
      dataIndex: "defaultCurrencyCode",
      key: "defaultCurrencyCode",
      render: (rec, data) => {
        return (
          <div className="d-flex align-items-center">
            <span
              style={{
                backgroundImage: `url(https://cdn.jsdelivr.net/npm/svg-country-flags@1.2.10/svg/${data?.taxResidencyCountryCode?.toLowerCase()}.svg)`,
                display: "inline-block",
                width: 25,
                height: 25,
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
                backgroundSize: "2.7rem",
                marginRight: 8,
              }}
            />
            {rec} ({currencyNames[rec]})
          </div>
        );
      },
    },
    {
      title: t("Global Accounts"),
      dataIndex: "accountId",
      key: "accountId",
      render: (rec) => {
        return bankAcc?.filter(
          (ele) =>
            (ele.accountId === rec || ele.masterAccountId === rec) &&
            ele.statusCode === "ACTIVE"
        )?.length;
      },
    },
    {
      title: t("ac_balance"),
      dataIndex: "balances",
      key: "balances",
      render: (record, data) => {
        return record?.[0]?.currencyCode ? (
          <>
            {currencyMapper[record?.[0]?.currencyCode]}

            {showBalance[data.accountId]
              ? amountFormat(record?.[0]?.availableBalanceAmount)
              : "xx.xx".toUpperCase()}
            {!showBalance[data.accountId] ? (
              <EyeInvisibleOutlined
                className="ml1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowBalance({
                    [data.accountId]: !showBalance[data.accountId],
                  });
                }}
              />
            ) : (
              <EyeOutlined
                className="ml1"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowBalance({
                    [data.accountId]: !showBalance[data.accountId],
                  });
                }}
              />
            )}
          </>
        ) : (
          "-"
        );
      },
    },
  ];

  useEffect(() => {
    if (location.state?.success) {
      alert(t("bank_account_added"));
      history.replace(location.pathname);
    }

    if (location.state?.success === false) {
      alert(t("failed_add_accounts"));
      history.replace(location.pathname);
    }

    dispatch(
      get_person_accounts({
        page_number: 1,
        page_size: 50,
        person_id: "ID-1146",
      })
    );
    dispatch(
      headerTitles({
        title: "Accounts",
        description: "Manage all your global accounts.",
      })
    );
    let locationSplit = location?.pathname?.split("/");
    dispatch({
      type: SideMenu,
      payload: {
        firstNav: "/" + locationSplit?.[1],
        secondNav: "/" + locationSplit?.[2],
        showChildren: true,
        childrenMenu: "/" + locationSplit?.[3],
      },
    });
  }, [location.state]);

  return (
    <>
      {/* <AddNewPrimaryAcc
        open={openNewAcc && hasWriteAccess}
        onClose={() => {
          setOpenNewAcc(false);
        }}
      /> */}
      <Modal
        visible={openNewAcc}
        className="right-alinged-modal"
        onCancel={() => {
          setOpenNewAcc(false);
        }}
        title={t("Add Account")}
      >
        <AddAccount
          setOpenAddNew={setOpenNewAcc}
          cb={() => {
            dispatch(
              get_person_accounts({
                page_number: 1,
                page_size: 50,
                person_id: "ID-1146",
              })
            );
          }}
        />
      </Modal>
      <Row wrap={false} style={{ flexGrow: 1, display: "flex" }}>
        <Col flex="auto" style={{ display: "flex", flexGrow: "1" }}>
          <div style={{ flexGrow: 1 }}>
            <div style={{ flexGrow: 1, paddingTop: "22px" }}>
              <div
                style={{
                  paddingLeft: "7px",
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                <div style={{ flexGrow: 1 }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div className="mr1">
                    <Input
                      className="pp-input"
                      size="large"
                      onChange={(e) => {
                        setSearchTerm(e.target.value?.toLowerCase());
                      }}
                      placeholder={t("searchh")}
                      prefix={<SearchOutlined style={{ color: "#B9B9B9" }} />}
                      style={{
                        backgroundColor: "#FAFAFA",
                        border: "1px solid #EAEAEA",
                        borderRadius: "5px",
                        width: "20rem",
                      }}
                    />
                  </div>
                  <div className="">
                    <Button
                      disabled={!hasWriteAccess}
                      type="primary"
                      className="pp-main-button"
                      icon={
                        <div style={{ paddingRight: "10px" }}>
                          <PlusCircleFilled />
                        </div>
                      }
                      onClick={() => setOpenNewAcc(true)}
                    >
                      <span>{t("addNew")}</span>
                    </Button>
                  </div>
                  {/* <div>
                    <Dropdown.Button
                      className='pp-dropdownbutton-menu'
                      style={{
                        borderRadius: "10px",
                      }}
                      menu={{
                        mode: "inline",
                        inlineCollapsed: false,
                        inlineIndent: 0,
                        items: [
                          {
                            type: "group",
                            label: (
                              <span
                                style={{
                                  color: "#B4B4B4",
                                  fontSize: "20px",
                                  lineHeight: "45px",
                                  fontWeight: "normal",
                                }}
                              >
                                {t("sort_by")}
                              </span>
                            ),
                            children: sortOptions.map((option) => ({
                              label: (
                                <span
                                  style={{
                                    color: "#454545",
                                    fontSize: "20px",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {option.label}
                                </span>
                              ),
                            })),
                          },
                          { type: "divider" },
                          {
                            label: (
                              <div
                                style={{
                                  paddingLeft: "7px",
                                  paddingTop: "9px",
                                  paddingBottom: "9px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    color: "#454545",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {t("add_new_customer")}
                                </span>
                              </div>
                            ),
                            key: "1",
                            icon: (
                              <div
                                style={{
                                  paddingTop: "9px",
                                  paddingBottom: "9px",
                                  display: "flex",
                                }}
                              >
                                <DownloadOutlined className="pp-icon-18" />
                              </div>
                            ),
                          },
                          {
                            label: (
                              <div
                                style={{
                                  paddingLeft: "7px",
                                  paddingTop: "9px",
                                  paddingBottom: "9px",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "18px",
                                    lineHeight: "22px",
                                    color: "#454545",
                                    fontWeight: "normal",
                                  }}
                                >
                                  {t("export_reports")}
                                </span>
                              </div>
                            ),
                            key: "2",
                            icon: (
                              <div
                                style={{
                                  paddingTop: "9px",
                                  paddingBottom: "9px",
                                  display: "flex",
                                }}
                              >
                                <DownloadOutlined className="pp-icon-18" />
                              </div>
                            ),
                          },
                        ],
                        onClick: () => {},
                      }}
                      size="large"
                      icon={
                        <EllipsisOutlined className="pp-icon-25" style={{}} />
                      }
                    />
                  </div> */}
                </div>
              </div>
            </div>

            <div
              className={bankAccLoading ? "shimmer" : ""}
              style={{ flexGrow: 1, paddingTop: "22px" }}
            >
              <TableComponent
                hasCheckbox={false}
                columns={columns}
                data={bankAcc?.filter(
                  (ele) => ele.accountTypeCode !== "VIRTUAL"
                )}
                loading={bankAccLoading}
                // selectedRows={selectedRows}
                // setSelectedRows={setSelectedRows}
                rowKey="bill_no"
                handleRowClick={(_e, data) => {
                  history.push("/account/detail", {
                    account: data,
                  });
                }}
              />
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PrimaryAcc;
