import { Button, Col, Row, Upload, message } from "antd";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { ExcelRenderer } from "react-excel-renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { get_cmp_accounts } from "../../../actions/accounts/accounts";
import SampleBulkTransfers from "../../../assets/images/Sample.xlsx";
import SampleBulkPayments from "../../../assets/images/sampleBulkPayments.xlsx";
import { FormInput } from "../../inputs";
import DateInput from "../../inputs/DateInput";
import "../transfer.css";
import { useHistory } from "react-router-dom";
import { LeftNavContext } from "../../../LeftNavProvider";

const UploadPaymentFirst = ({
  setdata,
  register,
  control,
  errors,
  setValue,
  setAttachments,
  type,
  setIsFileUpload,
  setFileErrs,
  hasWriteAccess,
  // values,
  from,
  step,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { setNavChildren } = useContext(LeftNavContext);
  useEffect(() => {
    dispatch(get_cmp_accounts());
  }, []);

  const validationSchema =
    type === "bulk_payment"
      ? Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          phoneNo: Yup.string().required("Phone number is required"),
          payment_amount: Yup.number()
            .typeError("Payment amount must be a number")
            .required("Payment amount is required"),
        })
      : Yup.object().shape({
          email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
          beneficiary_name: Yup.string().required(
            "Beneficiary name is required"
          ),
          payment_bank_name: Yup.string().required("Bank name is required"),
          payment_bank_account_no: Yup.string().required(
            "Bank account number is required"
          ),
          payment_bank_ifsc_number: Yup.string().required(
            "IFSC number is required"
          ),
          payment_amount: Yup.number()
            .typeError("Payment amount must be a number")
            .required("Payment amount is required")
            .test(
              "greaterThanZero",
              "Amount must be greater than 0",
              (value) => parseFloat(value) > 0
            ),
          phone: Yup.string().required("Phone number is required"),
        });

  const fileHandler = (fileList) => {
    let fileObj = fileList;
    setFileErrs({});
    if (
      fileList.name?.includes(".csv") ||
      fileList.name?.includes(".xlsx") ||
      fileList.name?.includes(".xls")
    ) {
      setIsFileUpload(fileList.name);
      ExcelRenderer(fileObj, (err, resp) => {
        if (err) {
          console.log(err);
        } else {
          let newRows = [];
          resp.rows.slice(1).map((row, index) => {
            if (row && row !== "undefined") {
              if (type === "bulk_payment") {
                newRows.push({
                  email: row[0],
                  phoneNo: row[1],
                  payment_amount: row[2],
                });
              } else {
                newRows.push({
                  email: row[1],
                  beneficiary_name: row[0],
                  payment_bank_name: row[2],
                  payment_bank_account_no: String(row[3]),
                  payment_bank_ifsc_number: String(row[4]),
                  payment_amount: row[5],
                  phone: String(row[6]),
                });
              }
            }
          });

          setdata(newRows);
          const validationErrorsFunc = async () => {
            const validationPromises = newRows.map(async (item, index) => {
              try {
                await validationSchema.validate(item, { abortEarly: false });
              } catch (validationError) {
                if (validationError.inner) {
                  const errorMessages = validationError.inner.reduce(
                    (acc, error) => {
                      acc[error.path] = error.message;
                      return acc;
                    },
                    {}
                  );
                  return { [index]: errorMessages };
                }
              }
              return null;
            });

            const validationResults = await Promise.all(validationPromises);
            const validationErrors = validationResults.filter(
              (result) => result !== null
            );

            setFileErrs(
              validationErrors?.reduce((acc, error) => {
                return { ...acc, ...error };
              }, {})
            );
          };
          validationErrorsFunc();
          setValue("list", newRows);
        }
      });
    } else {
      message.error(
        <span className="messageText">Please upload excel or csv file.</span>
      );
      return Upload.LIST_IGNORE;
    }
    return false;
  };

  const handleChange = (info) => {
    setAttachments(info?.file);
    setValue("file_name", info?.file?.name);
  };
  return (
    <>
      <div
        className="upload_payment_form"
        style={{ display: step === 0 ? "block" : "none" }}
      >
        <Row>
          <div className="center_line"></div>
          <Col span={12}>
            <div className="pl2">
              <div className="pp-form-item field_length">
                <FormInput
                  control={control}
                  {...register("payment_batchName", {
                    required: "Batch name is required",
                    onChange: (e) => {
                      setValue("batch_name", e?.target?.value);
                    },
                  })}
                  errors={errors}
                  label={t("batch_name")}
                  required={true}
                  // value={values?.batch_name}
                  placeholder={t("enter_batch")}
                />
              </div>
              {/* {type ? null : (
                <div className="pp-form-item field_length">
                  <FormSelect
                    inline
                    control={control}
                    {...register("debit_account", {
                      required: t('debit_error_msg'),
                      onChange: (e) => {
                        setValue("debit_account_uuid", e?.target?.value);
                      },
                    })}
                    required
                    errors={errors}
                    label={t('debit_account')}
                    placeholder={t('select')}
                    options={[{
                      label:"",
                      value:""
                    }]}
                  />
                </div>
              )} */}
              <div className="pp-form-item">
                <Upload
                  name="file"
                  beforeUpload={fileHandler}
                  className="upload_file"
                  onChange={handleChange}
                  maxCount={1}
                >
                  <button disabled={!hasWriteAccess} className="send-btn">
                    {t("upload_excel")}
                  </button>
                </Upload>
              </div>
              {type ? null : (
                <div className="pp-form-item field_length">
                  <DateInput
                    control={control}
                    {...register("schedule_payment_date", {
                      onChange: (e) => {
                        setValue(
                          "schedule_payment_date",
                          e?.target?.value ? moment(e?.target?.value) : null
                        );
                      },
                    })}
                    errors={errors}
                    label={t("schedule_payment")}
                    isTime={true}
                    picker={"date"}
                    minDate={new Date()}
                    // value={values?.schedule_payment_date ? values?.schedule_payment_date : null}
                  />
                  <span className="note_span">{t("transfer_transaction")}</span>
                </div>
              )}
              <div className="pp-form-item field_length">
                <FormInput
                  control={control}
                  {...register("batch_description", {
                    onChange: (e) => {
                      setValue("batch_description", e?.target?.value);
                    },
                  })}
                  errors={errors}
                  label={t("desc")}
                  placeholder={t("enter_desc")}
                  // value={values?.batch_description}
                />
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="right_div">
              <h4 className="right_header">{t("download_samples")}</h4>
              <span className="right_span">
                {t("plain_template")}
                <br />
                {t("without_employee")}
              </span>
              <Button
                className="right_button"
                href={type ? SampleBulkPayments : SampleBulkTransfers}
              >
                {t("download_sample_form")}
              </Button>
              <div className="or">or</div>
              <span className="right_span">{t("csv_template")}</span>
              <Button
                className="right_button"
                onClick={() => {
                  setNavChildren([])
                  history.push("/transfers/manage_beneficiary", {
                    fromPayLinks: from !== "bulk_payments",
                  });
                }}
              >
                {t("download_beneficiaries")}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UploadPaymentFirst;
