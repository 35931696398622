import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { get_all_payments } from "../../actions/z_accounts";
import { payment_status } from "../../config/helper";
import BaseCurrency from "../Common/BaseCurrency";
import NoTableData from "../Common/TableComp/NoTableData";
import TableComponent from "../Common/TableComp/TableComp";
import { p_types } from "../Payments/SendMoney/Recipients";
import { useHistory } from "react-router-dom";
// import { Onfido } from "onfido-sdk-ui";

const AccTxnTable = ({ account_number, dash }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const data = useSelector(
    (state) => state?.z_accs?.all_payments?.data?.data ?? []
  );

  const loading = useSelector((state) => state?.z_accs?.all_payments?.loading);
  useEffect(() => {
    if (account_number) dispatch(get_all_payments({}));
  }, []);

  const columns = [
    {
      title: t("txn_id"),
      dataIndex: "reference_number",
      key: "reference_number",
    },
    {
      title: t("beneficiary_name"),
      dataIndex: "counterparty_account_name",
      key: "counterparty_account_name",
    },
    {
      title: t("beneficiary_account"),
      dataIndex: "counterparty_account_number",
      key: "counterparty_account_number",
    },
    {
      title: t("Date and Time"),
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text).format("DD, MMM YY, HH:mm")}</div>,
    },
    {
      title: "Payment Method",
      dataIndex: "preferred_payment_scheme",
      key: "preferred_payment_scheme",
      render: (text) => p_types?.find((ele) => ele.value === text)?.label,
    },

    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (text) => (
        <div>
          <BaseCurrency />
          {text}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status_code",
      key: "status_code",
      render: (text) => (
        <span
          className={`status status_${payment_status[text]?.toLowerCase()}`}
        >
          {payment_status[text]}
        </span>
      ),
    },
  ];

  return (
    <>
      {!loading && data?.length === 0 ? (
        <NoTableData title={"No Transactions yet."} />
      ) : (
        <div className={loading ? "shimmer" : ""}>
          <TableComponent
            loading={loading}
            rowKey="id"
            columns={columns}
            shimmerCount={5}
            hasCheckbox={false}
            data={Array.isArray(data) ? (dash ? data?.slice(0, 5) : data) : []}
            pagination={null}
            scroll={{
              x: 1450,
            }}
          />
          {data?.slice(0, 5)?.length ? (
            <div className="flex-end mt1 mb1 mr1">
              <span
                className="active-text cursor"
                onClick={() => {
                  history.push("/transactions");
                }}
              >
                View all
              </span>
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default AccTxnTable;
